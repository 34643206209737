*:active,
*:focus {
    outline: none;
}

::selection {
    background: #b3d4fc; // WebKit/Blink Browsers
}

::-moz-selection {
    background: #b3d4fc; // Gecko Browsers
}
