/**************************\
  Basic Modal Styles
\**************************/


.modal__overlay {

    @include fixed(0, 0, 0, 0, 400);
    background: rgba($color-site-black, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    @include transition(opacity);
    will-change: opacity;
}

.modal__container {
    @include utils(flex, rounded-2);
    background: $color-site-white;
    width: 100%;
    display: flex;
    flex-direction: column;
    transform: translateY(10%);
    max-width: 800px;
    overflow-y: scroll;
    @include transition(transform);
    will-change: transform;

    &.--small {
        @include utils(max-w-sm);
    }

    &.--large {
        @include breakpoint-up('md+') {
            max-width: rem(900px);
        }

        @include breakpoint-up('lg') {
            max-width: rem(1200px);
        }
    }
}

.modal__container--sm {
    max-width: 440px;
}

.modal__inner {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal__footer {
    bottom: 0;
    left: 0;

    @include utils(
        'absolute',
        'flex',
        'flex-jc-between',
        'flex-ai-center',
        'border',
        'border-top',
        'w-100',
        'bg-white',
        'rounded-bottom-2'
    );


    &::before {
        content: '';
        display: block;
        height: 20px;
        position: absolute;
        top: -20px;
        width: 100%;
        left: 0;
        background-repeat: no-repeat;
        background-position: 0 -1px;
        background-size: 95% 100%;
        background-image: linear-gradient(
            0deg,
            $color-site-white 0%,
            rgba($color-site-white, 0) 100%
        );
    }
}


// ---
// Full screen in mobile modifiers.
// ---
.modal--mobile-full-screen {
    @include breakpoint-down('sm+') {
        .modal__container {
            max-height: none;
            max-width: none;
            height: 100%;
            border-radius: 0;
        }

        .modal__footer {
            top: 0;
            bottom: auto;
            border-bottom: 1px solid $color-site-black;
            border-top: 0;
            border-radius: 0;

            &::before {
                display: none;
            }
        }
    }
}




// ---
// Transitions and visibility.
// ---

.modal[aria-hidden='false'] {
    .modal__overlay {
        opacity: 1;
    }

    .modal__container {
        animation: slideInUp 0.3s $easing-in;
    }
}

.modal[aria-hidden='true'] {
    .modal__overlay {
        opacity: 0;
    }

    .modal__container {
        animation: slideOutDown 0.3s $easing-out;
    }
}


.modal--close {
    position: absolute;
    cursor: pointer;
    right: 34px;
    top: 34px;
}


// ---
// Side Effects
// ---
html.modal-open,
html.modal-open body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

// Prevents background scrolling when menu open
.overlay-open {
    overflow: hidden;
    height: 100%;
}
