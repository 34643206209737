
// sass-lint:disable single-line-per-selector
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
.block-item,
hr,
table,
fieldset, figure,
pre {
    // margin-top: $spacing-small;
    // margin-bottom: $spacing-small;
    margin-top: 1em;
    margin-bottom: 0;

    &:first-child {
        margin-top: 0;
    }
}

ul, ol {
    margin-left: 20px;
}