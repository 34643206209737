.overlay {
    background-color: rgba($color-primary, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &:after {
        content: "×";
        position: absolute;
        top: 0;
        right: 0;
        background: $color-dark-grey;
        color: $color-primary-inverse;
        cursor: pointer;
        display: inline-block;
        font-size: rem(36px);
        line-height: 1;
        padding: .45em .7em .5em;
    }
}

.overlay__video {
    height: 100%;
    width: 100%;
}
