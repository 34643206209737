.footer {
    background: url("/static/images/footer-bg.jpg") #000 no-repeat center center; 
    background-size: cover;

    color: $color-primary-inverse;


    h1, h2, h3, h4, h5, h6 {
        opacity: 0.5;
    }

    p {
        font-size: rem(16px);
    }

    hr {
        border-top: rem(2px) solid $color-primary-inverse;
        opacity: 0.1;
    }

    .email-input {
        border: none;

    }
}

.footer-nav {

    @include narrow {
        overflow: hidden;
    }

    @include list-clear;
    li {
        color: $color-primary-inverse;
        display: block;
        margin-bottom: rem(20px);
    }

    span {
        opacity: 0.5;
    }

    a {
        font-size: rem(16px);
        color: $color-primary-inverse;
        display: block;
        margin-bottom: rem(20px);
        opacity: 0.8;

        &:hover {
            opacity: 1;
            text-decoration: underline;
        }
    }
}

.footer__message {
    color: #BBB;

    a {
        color: #FFF;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__social {
    margin-top: 30px;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @include large {
        margin-top: 0;
        flex-direction: initial;
    }

}

.footer__image-link {

    > img {
        max-width: 85%;
    }

    &:hover {
        opacity: $transparency;
    }
}

.social-links {
    margin-right: 15px;

    @include large {
        margin-right: 0;
        margin-left: 15px;
    }

}

.social-link {
    width: rem(48);
    height: rem(48);
    margin-left: rem(8);
    background-color: $color-secondary;

    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: $base-transition;

    &:hover {
        transition: none; // Removing transition here makes it feel snappy
        background-color: $color-secondary-hover;
    }


    span {
        @extend .u-accessible;
    }

    &:first-child {
        @include medium-down {
            margin-left: 0;
        }
    }

    // .icon {
    //     display: block; // overrides 'inline-block' which is causing weird vertical alignment issues
    //     margin-left: auto;
    //     margin-right: auto;
    //     vertical-align: middle;
    // }
}

.social-link_text {
    vertical-align: text-bottom;
}

.icon--facebook {
    width: rem(13);
    height: rem(26);
    background-image: icon(
        facebook,
        $color: $color-primary-inverse
    );
}

.icon--twitter {
    width: rem(26);
    height: rem(21);
    background-image: icon(
        twitter,
        $color: $color-primary-inverse
    );
}

.icon--instagram {
    width: rem(26);
    height: rem(26);
    background-image: icon(
        instagram,
        $color: $color-primary-inverse
    );
}

// .social-link--youtube {}
// .social-link--linkedin {}





// Form subsribe
// -----------------------------------------------------------------------------



.form--footer {

    .form-layout {
        display: flex;

        @include xsmall {
            display: block;
        }

    }

    .input--sign-up {
        flex: 1 1 100px;
        border: none;
        margin-right: 10px;
        max-width: 420px;

        @include xsmall {
            max-width: 100%;
        }

    }

}








