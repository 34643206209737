// Margin zero
// -----------------------------------------------------------------------------

.u-mb-0 {
    margin-bottom: 0 !important;
}

.u-mt-0 {
    margin-top: 0 !important;
}

.u-ml-0 {
    margin-left: 0 !important;
}

%no-top-margin-first {
    &:first-child {
        margin-top: 0;
    }
}

%no-bottom-margin-last {
    &:last-child {
        margin-bottom: 0;
    }
}

.u-m-reset {
    margin: 0!important;
}


// Margin top-only
// -----------------------------------------------------------------------------

.u-mt--xxlarge {
    margin-top: $spacing-xxlarge;
}

.u-mt--xlarge {
    margin-top: $spacing-xlarge;
}

.u-mt--large {
    margin-top: $spacing-large;
}

.u-mt--medium {
    margin-top: $spacing;
}

.u-mt--small {
    margin-top: $spacing-small;
}

.u-mt--xsmall {
    margin-top: $spacing-xsmall;
}


// Margin bottom-only
// -----------------------------------------------------------------------------

.u-mb--xlarge {
    margin-bottom: $spacing-xlarge;
}

.u-mb--large {
    margin-bottom: $spacing-large;
}

.u-mb--medium {
    margin-bottom: $spacing;
}

.u-mb--small {
    margin-bottom: $spacing-small;
}

.u-mb--xsmall {
    margin-bottom: $spacing-xsmall;
}



// Margin right-only
// -----------------------------------------------------------------------------

.u-mr--medium {
    margin-right: $spacing;
}

.u-mr--xsmall {
    margin-right: $spacing-xsmall;
}


// Margin left-only
// -----------------------------------------------------------------------------

.u-ml--medium {
    margin-left: $spacing;
}

.u-ml--small {
    margin-left: $spacing-small;
}

.u-ml--xsmall {
    margin-left: $spacing-xsmall;
}


// Media queries
// -----------------------------------------------------------------------------

// .u-m-0--medium-only {
//     @include medium-only {
//         margin: 0;
//     }
// }

.u-mt--small--medium-down-only {
    @include medium-down {
        margin-top: $spacing-small;
    }
}

.u-mt--medium--medium-down-only {
    @include medium-down {
        margin-top: $spacing;
    }
}
