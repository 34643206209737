// Remove the unit
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

// convert pixel to em
@function em($size, $em-base: 16) {
    $em-size: $size / $em-base;
    @return #{$em-size}em;
}

// convert pixel to rem
@function rem($size) {
    $rem-size: strip-unit($size) / strip-unit($base-font-size);
    @return #{$rem-size}rem;
}

// convert a pixel line height to relative value
@function line-height($font-size, $line-height) {
    $relative-line-height: $line-height / $font-size;
    @return $relative-line-height;
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function urlEncode($string) {
    $map: (
        "<": "%3C",
        ">": "%3E",
        "#": "%23",
        "/": "%2F",
        ":": "%3A",
        "=": "%3D",
        " ": "%20",
        '"': "%22",
    );
    $new: $string;
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}

@function buildIcon($parameters) {
    $icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="#{map_get($parameters, width)}" height="#{map_get($parameters, height)}">#{map_get($parameters, path)}#{map_get($parameters, extra)}</svg>';
    @return urlEncode($icon);
}

@function buildPath($path, $parameters) {
    $icon: '<path fill="#{map_get($parameters, color)}" stroke="#{map_get($parameters, stroke-color)}" stroke-width="#{map_get($parameters, stroke-width)}" stroke-linecap="#{map-get($parameters, stroke-linecap)}" style="#{map_get($parameters, css)}" d="#{$path}"/>';
    @return $icon;
}
