/* Default styling for content blocks from Streamfield */

.content_stream_block {
	padding-top: $spacing;
	padding-bottom: $spacing;
}

.content_stream_block--promotion_block {
	padding-bottom: 0;
}

.content_stream_block--sponsor_group_block {
	padding: 0;
}
