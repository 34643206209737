 table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

    th {
        text-align: left;
        border-bottom: 1px solid #ccc;
        padding: 18px 0 14px;
        margin-bottom: 5px;
    }

    td {
        padding: 6px 0;
    }
