// Padding all sides
// ----------------------------------------------------------------------------

.u-padding--xlarge {
    padding: $spacing-xlarge;
}

.u-padding--large {
    // padding: 5rem;
    padding: $spacing-large;
}

.u-padding--medium {
    padding: $spacing;
}

.u-padding--small {
    // padding: 2rem;
    padding: $spacing-small;
}

.u-padding--xsmall {
    // padding: 1rem;
    padding: $spacing-xsmall;
}


// Padding top-only
// ----------------------------------------------------------------------------

.u-pt--xlarge {
    //padding-top: 7rem;
    padding-top: $spacing-xlarge;
}

.u-pt--large {
    // padding-top: 5rem;
    padding-top: $spacing-large;
}

.u-pt--medium {
    padding-top: $spacing;
}

.u-pt--small {
    // padding-top: 2rem;
    padding-top: $spacing-small;
}

.u-pt--xsmall {
    padding-top: $spacing-xsmall;
}


// Padding bottom-only
// ----------------------------------------------------------------------------

.u-pb--xxlarge {
    padding-bottom: rem(140);
}

.u-pb--xlarge {
    // padding-bottom: 7rem;
	padding-bottom: $spacing-xlarge;
}

.u-pb--large {
    // padding-bottom: 5rem;
    padding-bottom: $spacing-large;
}

.u-pb--medium {
    padding-bottom: $spacing;
}

.u-pb--small {
    // padding-bottom: 2rem;
    padding-bottom: $spacing-small
}

.u-pb--xsmall {
    padding-bottom: $spacing-xsmall
}


// Padding right-only
// ----------------------------------------------------------------------------

.u-pr--medium {
	padding-right: $spacing;
}

    // .md-only-u-pr--medium {
    //     @include medium-down {
    //         padding-right: $spacing;
    //     }
    // }

