.gallery {
    position: relative;
}


.gallery__buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 1rem;

    .btn {
        @include medium {
            display: block;
            float: right;
            clear: both;
            margin-bottom: 0.5rem;
        }
    }
}
