
// -----------------------------------------------------------------------------
// Burger icon
// -----------------------------------------------------------------------------

.nav-header-mobile {
    #burger,
    .nav__link--search {
        @include medium {
            display: none;
        }
    }
}


.mobile-menu-toggle {
    margin-left: 0;
    padding-left: 0;
    
    .panel-one & { 
      // margin-bottom: -13px;
      display: block;
      height: 81px;
      padding-top: 30px;
      padding-left: 30px;
      border-bottom: 1px solid rgba(231, 231, 231, 0.2);
    }

    .global-nav-mobile & {

    }


}


#burger {
    width: 20px;
    height: 14px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    @include medium {
        margin-left: auto;
        margin-right: auto;
    }

}

#burger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}


#burger span:nth-child(1) {
  top: 0px;
}

#burger span:nth-child(2),#burger span:nth-child(3) {
  top: 6px;
}

#burger span:nth-child(4) {
  top: 12px;
}

.mobile-menu--visible #burger span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.mobile-menu--visible #burger span:nth-child(2) {
  transform: rotate(45deg);
}

.mobile-menu--visible #burger span:nth-child(3) {
  transform: rotate(-45deg);
}

.mobile-menu--visible #burger span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}