// Minimal style to prevent conflicting with other links
// and make it easy to override

a {
    color: #005A74;
    text-decoration: none;

    &:hover {
        color: darken(#005A74, 5%)
    }
}


// Basic link, has visited states
.link--basic {
    &:visited {
        color: rgba(#27323E, 0.5);

        &:hover {
            color: darken(#27323E, 50%)
        }
    }
}


// Border styles for links
.link--border {
    border-bottom: 1px solid;
    text-decoration: none;
    border-bottom-color: inherit;
}
