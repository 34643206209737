@mixin utils($classNames...) {
    $all-maps: merge-maps(
        $borders,
        $border-radius,
        $shadows,
        $display,
        $flex,
        $float,
        $mouse,
        $opacity,
        $position,
        $placement,
        $text,
        $dimensions,
        $lists,
        $z-index,
        $color-class-map,
        $grid-column-classes
    );

    @include make-util-mixin('utils', $all-maps, $classNames);
}

@mixin spacing($classNames...) {
    @include make-util-mixin('spacing', $spacing, $classNames);
}

// https://material.io/design/motion/speed.html#easing
$easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
$easing-in: cubic-bezier(0, 0, 0.2, 1);
$easing-out: cubic-bezier(0.4, 0, 1, 1);
$easings: (
    standard: $easing-standard,
    in: $easing-in,
    out: $easing-out,
);

@mixin transition($props, $duration: 0.3s, $ease: standard) {
    $result: ();
    $easing: map-get-strict($easings, $ease);

    @for $i from 1 through length($props) {
        $prop: nth($props, $i);
        $result: append($result, $prop);
        $result: append($result, $duration);
        $result: append($result, $easing);
        @if $i != length($props) {
            $result: append($result, unquote($string: ','));
        }
    }

    transition: $result;
}

@mixin print {
    @media print {
        @content;
    }
}


// media query to target IE 9/10/11
// @include internet-explorer-only { your rules here }
@mixin internet-explorer-only {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// CSS specific to iOS devices
@mixin ios-safari-only {
    @supports (-webkit-overflow-scrolling: touch) {
        @content;
    }
}
