svg {
    overflow: hidden;
    vertical-align: middle;
    fill: currentColor;
    pointer-events: none;
}

.svg-max-width {
    svg {
        max-width: 100%;
    }
}
