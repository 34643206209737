// Utility classes for text styling
// -----------------------------------------------------------------------------

s,
.u-strikethrough {
    text-decoration: line-through; // Why the fancy strikethrough? Fanciness was messing with stuff
    // text-decoration: none;
    // position: relative;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     top: 50%;
    //     left: 0;
    //     border-top: 1px solid $color-primary;
    // }
}

.u-text-bold {
    font-weight: $font-weight-bold;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-text-underline {
    text-decoration: underline !important;
}

// * 1. Ensures text doesn't wrap and break your layout
// * 2. Trigger overflow behavior
// * 3. Truncate the text
.u-text-truncate {
    overflow: hidden;               // [2]
    text-overflow: ellipsis;        // [3]
    white-space: nowrap;            // [1]
}

.u-hyphen {
    @include xsmall {
        // These are technically the same, but use both
        overflow-wrap: break-word;
        word-wrap: break-word;
        // This is the dangerous one in WebKit, as it breaks things wherever
        word-break: break-all;
        // Instead use this non-standard one:
        word-break: break-word;
        // Adds a hyphen where the word breaks, if supported (No Blink)
        hyphens: auto;
    }
}
