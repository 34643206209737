// Generic mobile menu

.mobile-menu {
    @include fixed(0,0,0,0,110);
    margin-top:74px;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    animation-duration: .3s;
    animation-fill-mode: both;
    will-change: transform,opacity;
    overflow-y: scroll !important;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    height: 100%;
    background-color: rgba(#050505, 0.98);
    padding: 0 1.5rem 8rem;

    a {
        color: white;

        &:hover {
            opacity: .75;
        }
    }

    @include medium {
        display: none;
    }

}

body.mobile-menu--visible {
    overflow: hidden;
    position: fixed;

    @include medium {
        overflow: scroll;
    }

    .mobile-menu {
        animation-name: slideIn;
    }
}

body.mobile-menu--hidden {
    .mobile-menu {
        animation-name: slideOut;
    }
}


.mobile-nav__link {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1.25rem 0;
    border-bottom: 1px solid rgba(#424242, .5);
}

.mobile-sub-menu {
    margin-top: 50px;
}

.mobile-sub-menu__link {
    display: block;
    padding: .5rem 0;
}

.mobile-icon-nav__link {
    padding: .5rem 0 .75rem .5rem;
}

// Prevents mobile menu background scrolling when menu open
.overlay-open {
    overflow:hidden;
    height:100%;
}
