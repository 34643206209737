// -----------------------------------------------------------------------------
// Wrappers for centering content
// -----------------------------------------------------------------------------


// Base
// ----------------------------------------------------------------------------

.wrapper {
    @include clearfix;
    margin-right: auto;
    margin-left: auto;
    max-width: 1560px;
}

	.wrapper--small {
	    max-width: 780px;
	}


// Padding
// ----------------------------------------------------------------------------

.wrapper--plr--small {
    padding-left: 2em;
    padding-right: 2em;
}

.wrapper--ptb--small {
    padding-top: 2em;
    padding-bottom: 2em;
}


// .content-width keeps content off the margins
// ----------------------------------------------------------------------------

.content-padding {
	// Default
	padding-left: $content-padding;
	padding-right: $content-padding;

	@include medium {
		padding-left: $content-padding--medium;
		padding-right: $content-padding--medium;
	}

    @include large {
	    padding-left: $content-padding--large;
	    padding-right: $content-padding--large;
	};

    &.--bleed {
    	padding-left: 0;
    	padding-right: 0;
    }

}

// TODO: Better naming for this?
.content-padding-vertical {
	padding-top: $spacing-xlarge;
	padding-bottom: $spacing-xlarge;

	@include xsmall {
		padding-top: $spacing-large;
	}
	
	@include large {
		padding-top: $spacing-xlarge;
		padding-bottom: 8rem;
	}
}

.content-padding--small-only {
	@include xsmall {
		padding-left: $content-padding;
		padding-right: $content-padding;
	}
}