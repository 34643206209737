
.btn {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: $base-transition;
    text-decoration: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-appearance: none;

    border: none;

    @include small {
        max-width: 100%;
    }

    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: default;
        opacity: .5 !important;
    }
}


// Size modifiers
// ----------------------------------------------------------------------------

.btn--small {
    font-size: 18px;
    line-height: 14px;
    padding: 10px 18px;
}

.btn--large {
    font-size: 25px;
    line-height: 1.25;
    padding: 15px 25px;

}


// Colors modifiers
// ----------------------------------------------------------------------------


.btn--blue {
    background-color: $color-primary;
    color: white;

    &:hover {
        background-color: darken($color-primary, 10%);
    }


    &.inverted {
        background-color: rgba($color-primary, 0.1);
        color: $color-primary;

        &:hover {
            background-color: rgba($color-primary, 0.2);
        }

    }

}


// Btn group
// ----------------------------------------------------------------------------


.btn--group {
    > .btn {
        display: inline-block;
        margin: 0 10px;
    }
}


.btn--group-stack {
    > .btn {
        display: block;
        margin: 50px 0 0;
    }
}


.btn--block {
    display: block;
}

.btn--full {
    width: 100%;
}

.btn--group .btn {
    margin: 50px auto 0;
}


