// Fonts
// ----------------------------------------------------------------------------
$font-family-primary-sans-serif: "brandon-grotesque", 'helvetica neue', helvetica, arial, sans-serif;
$font-family-secondary-sans-serif: "proxima-nova", 'helvetica neue', helvetica, arial, sans-serif;

$base-font-size:        16px;
$base-line-height:      24px;

$line-height-headline:  1.45;

$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-bold:      700;
$font-weight-xbold:     800;
$font-weight-black:     900;


// Spacing
// ----------------------------------------------------------------------------
$base-spacing-unit:     1.5rem; // 24px

$base-padding:          $base-spacing-unit;

$col-spacing:           1rem; // 16px
$col-spacing--medium: 	2rem; // 32px
$col-spacing--large:    3rem; // 48px

$content-padding:           1rem; // 16px
$content-padding--medium: 	2rem; // 32px
$content-padding--large:    3rem; // 48px


// Default
$spacing:               $base-spacing-unit; // 24px

// Larger
$spacing-xxlarge:		($spacing * 5);		// 120px
$spacing-xlarge:		($spacing * 3);		// 72px
$spacing-large:         ($spacing * 1.5); 	// 36px

// Smaller
$spacing-small:         ($spacing / 3 * 2); // 16px
$spacing-xsmall:        ($spacing / 3); 	// 8px



// Others
// ----------------------------------------------------------------------------
$base-transition:       all .1s ease-in;

// Colours
// ----------------------------------------------------------------------------
$color-site-black:  #27323E;
$color-primary:     #00B7EA;
$color-secondary:   #EC8500;
