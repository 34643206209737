
$shadow-depth: 8px;
$shadow-depth--small: 6px;

.card {
    margin-bottom: 1rem;
}

.card {
    // Give ever fourth card a different position
    &:nth-child(4n+1) .card__image--shadow { box-shadow: rem($shadow-depth--small)  rem($shadow-depth--small)  0 0 $color-grey; } // bottom right
    &:nth-child(4n+2) .card__image--shadow { box-shadow: rem($shadow-depth--small)  rem(-$shadow-depth--small) 0 0 $color-grey; } // bottom left
    &:nth-child(4n+3) .card__image--shadow { box-shadow: rem(-$shadow-depth--small) rem($shadow-depth--small)  0 0 $color-grey; } // top right
    &:nth-child(4n+4) .card__image--shadow { box-shadow: rem(-$shadow-depth--small) rem(-$shadow-depth--small) 0 0 $color-grey; } // top left

    &:hover {
        // CSS variables would make this better.. https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_variables
        &:nth-child(4n+1) .card__image--shadow { box-shadow: rem($shadow-depth--small)  rem($shadow-depth--small)  0 0 $color-secondary; } // bottom right
        &:nth-child(4n+2) .card__image--shadow { box-shadow: rem($shadow-depth--small)  rem(-$shadow-depth--small) 0 0 $color-secondary; } // bottom left
        &:nth-child(4n+3) .card__image--shadow { box-shadow: rem(-$shadow-depth--small) rem($shadow-depth--small)  0 0 $color-secondary; } // top right
        &:nth-child(4n+4) .card__image--shadow { box-shadow: rem(-$shadow-depth--small) rem(-$shadow-depth--small) 0 0 $color-secondary; } // top left
    }
}

.card_large {
    // Give ever fourth card a different position
    &:nth-child(4n+1) .card__image--shadow { box-shadow: rem($shadow-depth)  rem($shadow-depth)  0 0 $color-grey; } // bottom right
    &:nth-child(4n+2) .card__image--shadow { box-shadow: rem($shadow-depth)  rem(-$shadow-depth) 0 0 $color-grey; } // bottom left
    &:nth-child(4n+3) .card__image--shadow { box-shadow: rem(-$shadow-depth) rem($shadow-depth)  0 0 $color-grey; } // top right
    &:nth-child(4n+4) .card__image--shadow { box-shadow: rem(-$shadow-depth) rem(-$shadow-depth) 0 0 $color-grey; } // top left

    &:hover {
        &:nth-child(4n+1) .card__image--shadow { box-shadow: rem($shadow-depth)  rem($shadow-depth)  0 0 $color-secondary; } // bottom right
        &:nth-child(4n+2) .card__image--shadow { box-shadow: rem($shadow-depth)  rem(-$shadow-depth) 0 0 $color-secondary; } // bottom left
        &:nth-child(4n+3) .card__image--shadow { box-shadow: rem(-$shadow-depth) rem($shadow-depth)  0 0 $color-secondary; } // top right
        &:nth-child(4n+4) .card__image--shadow { box-shadow: rem(-$shadow-depth) rem(-$shadow-depth) 0 0 $color-secondary; } // top left
    }
}

.card__heading {
    margin-top: rem(16px);
    // margin-bottom: 0;
    transition: none;

    a {
        color: $color-primary;
    }

    &.--override {
        text-transform: none;
    }
}

.card__date {
    font-weight: 700;
}
