// -----------------------------------------------------------------------------
// Media blocks
// -----------------------------------------------------------------------------
//
// Float an image to the left, with a clearfixed block of content to the right.
//
// <div class='media'>
//     <img src='assets/images/green-bird.jpg' class='media__image'>
//     <div class='media__body'>
//         <p>
//             The text
//         </p>
//     </div>
// </div>


$spacing-media: 1em;

.media,
.media--rev,
.media--middle-align {
    @include clearfix;
}

.media__image {
    float: left;
    margin-right: $spacing-media;
}

.media__body {
    @include clearfix;
    overflow:hidden;
    _overflow:visible;
    zoom:1;
}

.media__video {
    height: 0;
    padding-bottom: 60%;
    position: relative;
}

.media__video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


// Swap the image to the right
.media--rev > .media__image {
    float: right;
    margin-right: 0;
    margin-left: $spacing-media;
}

.media--rev > .media__body {
    @include clearfix;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.media--middle-align {
    display: table;

    > .media__body {
        display: table-cell;
        vertical-align: middle;

        > * {
            margin: 0;
        }
    }
}
