
.u-hidden {
    display: none;
}

.u-hidden--narrow {
    @include narrow {
        display: none;
    }
}

.u-hidden--small {
    @include small {
        display: none;
    }
}

.u-hidden--medium {
    @include medium {
        display: none;
    }
}

.u-hidden--medium-down {
    @include medium-down {
        display: none;
    }
}

.u-hidden--large {
    @include large {
        display: none;
    }
}

.u-hidden--medium-only {
    display: none;

    @include narrow {
        display: block;
    }

    @include large {
        display: block;
    }
}


// Hidding elements on Touch devices
.touch .u-hidden-touch {
    display: none;
    visibility: hidden;
}

// Hidding elements on Non Touch screens
.no-touch .u-hidden-no-touch {
    display: none;
    visibility: hidden;
}
