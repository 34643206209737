.filter {

}

.filter_tags {
    font-size: 0;

    .filter__item {
        font-weight: $font-weight-xbold;
        margin: 0 rem(8px) rem(8px) 0;
        padding: rem(5px) rem(15px);
        letter-spacing: 0.05em;

        @include relative-font(
            $font-size: 19px,
            $line-height: 28px
        )
    }

    .filter__item-select-wrapper {
        padding-right: 2rem;
    }
}

.filter_date {
    font-size: 0;

    .filter__item {
        margin: 0 rem(8px) rem(8px) 0;
        padding: rem(6px) 0;
        width: rem(70px);
    }

    .filter__item-upper, .filter__item-content, .filter__item-lower {
        display: block;
        text-align: center;
        letter-spacing: 0.05em;
    }

    .filter__item-upper {
        font-weight: $font-weight-xbold;

        @include relative-font(
            $font-size: 12px,
            $line-height: 14px
        );
    }

    .filter__item-content {
        font-weight: $font-weight-bold;

        @include relative-font(
            $font-size: 40px,
            $line-height: 48px
        );
    }

    .filter__item-lower {
        font-weight: $font-weight-medium;

        @include relative-font(
            $font-size: 12px,
            $line-height: 14px
        );
    }
}

.filter__item {
    background-color: $color-primary;
    color: $color-primary-inverse;
    display: inline-block;
    text-transform: uppercase;
    // transition: $base-transition;
    // transition: all 0.05s ease-in;

    &:hover {
        background-color: #fff;
        color: $color-secondary;
        transition: none;

        /* Trying to make filters 'pop up' on hover, but causes nasty loop
         * when hovering on the bottom few pixels */
        // position: relative;
        // top: rem(-2px);

        /* Attempt 2, may revist if time
         * TODO: Improve hover state on filters if time */
        // transform: translateY(-3px);
        // border-bottom: solid 3px transparent;

        // Or this
        transform: rotate(-3deg);
    }
}

.filter__item-select-wrapper {
    @include arrow(
        $orientation: 'down',
        $color: $color-primary-inverse,
        $size: 0.4rem
    );

    &:after {
        right: 0.5rem;
        top: 1rem;
        transition: $base-transition;
    }

    &:hover {
        .filter__item-select {
            color: $color-secondary;
        }

        &:after {
            border-top-color: $color-secondary;
        }
    }
}

.filter__item-select {
    color: $color-primary-inverse;
    text-transform: uppercase;
    font-weight: $font-weight-xbold;
    padding: 0;
    margin: 0;
    letter-spacing: 0.05em;
    border: none;
    background-image: none;

    @include relative-font(
        $font-size: 19px,
        $line-height: 28px
    );
}

// Jumpy links! Is this weird?! The power is going to my head!
.filter__item:nth-child(5n+1):hover { transform: rotate(4deg); }
.filter__item:nth-child(5n+2):hover { transform: rotate(-3deg); }
.filter__item:nth-child(5n+3):hover { transform: rotate(2deg); }
.filter__item:nth-child(5n+4):hover { transform: rotate(-2deg); }
.filter__item:nth-child(5n+5):hover { transform: rotate(-4deg); }

.filter__item_active {
    &,
    &:hover {
        background-color: $color-primary-inverse;
        color: $color-primary;
    }
}
