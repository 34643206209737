
// Utility font sizes
// -----------------------------------------------------------------------------



.u-muted {
    opacity: $transparency;
}

.note {
    @extend .u-xsmall;
    color: $color-dark-grey;
    // opacity: $transparency;

    // @include relative-font(
    //     $font-size: 14px,
    //     $line-height: 19px
    // );

    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: $color-secondary;
        }
    }

    & + & {
        margin-top: 0.57em;
    }
}


.u-color-primary {
    color: $color-primary;
}

.u-color-secondary {
    color: $color-secondary;
}

.u-color-secondary-inverse {
    color: $color-secondary-inverse;
}

.u-text-medium {
    font-weight: $font-weight-medium;
}

.u-review {
    font-family: STIXGeneral, serif;
    margin-bottom: 0;

    @include relative-font(
        $font-size: 41px,
        $line-height: 36px
    );
}

.u-text-no-transform {
    text-transform: none;
}

.u-big-underline {
    padding-bottom: 0.2em;
    border-bottom: solid 0.2em #000;
}

 .u-sentiment-error {
    color: $color-tertiary;
     font-weight: bold;
 }

.u-inline-block {
    display: inline-block;
}
