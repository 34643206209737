
form {
    @include placeholder {
        color: rgba(#27323E, 0.5);
        font-weight: 400;
    }
}

input, select {
    border: none;
    padding: 10px 0 15px 0;
    resize: none;
    background: transparent;

    &:focus {
        // outline: 2px solid $color-focus;
    }

}

fieldset {
    padding: 0;
    border: 0;
}

legend {
    font-size: $base-font-size;
    font-weight: $font-weight-bold;
}

input + button {
    margin-top: 1em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black!important;
    color: black;
}


// input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px white inset;
//     -webkit-text-fill-color: yellow !important; // to change type color
// }

// input:-webkit-autofill {}
// $color-text

// /* Change the white to any color ;) */

