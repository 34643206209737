// Elements
// -----------------------------------------------------------------------------

h1, h2, h3, h4
.h1, .h2, .h3, .h4 {
    line-height: 1.3;

    &:first-child {
        margin-top: 0;
    }
}

h1,
.h1 {
    font-size: 2.5em;
}

h2,
.h2 {
    font-size: 1.8em;
}

h3,
.h3 {
    font-size: 1.5em;
}

h4,
.h4 {
    font-size: 1.25em;
}

h5,
.h5 {
    font-size: 1em;
}

h6,
.h6 {
    font-size: 0.75em;
}

p {
    // margin-bottom: 1em;
}