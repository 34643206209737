.dropdown-menu {
    $arrow-size: 0.5rem;

    background-color: $color-site-off-white;
    box-shadow: 0 rem(15px) rem(20px) rem(-10px) rgba(0,0,0,0.3);
    padding: rem(20px);
    text-align: left;
    z-index: 5;

    @include arrow(
        $orientation: 'up',
        $color: $color-site-off-white,
        $size: $arrow-size
    );
    @include list-clear;

    &:after {
        top: -($arrow-size - 0.05rem);
        left: rem(20px);
    }

    .dropdown-menu__item-link {
        color: $color-primary;

        &:hover {
            color: $color-secondary;
        }
    }

    .dropdown-menu__item_active {
        color: $color-secondary;
    }

    position: absolute;
}

.dropdown-menu_secondary {
    background-color: $color-secondary;
    color: $color-secondary-inverse;

    &:after {
        border-bottom-color: $color-secondary;
    }

    .dropdown-menu__item-link {
        color: $color-secondary-inverse;
    }
}

.dropdown-menu__item-link {
    display: block;
    font-weight: $font-weight-bold;
    margin-bottom: rem(10px);

    @include relative-font(
        $font-size: 18px,
        $line-height: 23px
    );
}

.dropdown-menu__item_active {
    color: $color-secondary;
}

.dropdown-menu__content {

}
