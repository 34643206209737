html {
    font-family: $font-family-primary;
}

::selection {
    background-color: $color-secondary;
    color: $color-secondary-inverse;
}

::-moz-selection {
    background-color: $color-secondary;
    color: $color-secondary-inverse;
}
