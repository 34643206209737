// ----------------------------------------------------------------------------
// Breakpoints and simple media query mixins
// ----------------------------------------------------------------------------

$breakpoint-xsmall: 480px;
$breakpoint-small: 600px;
$breakpoint-medium: $breakpoint-small + 1px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1320px;


// Ascending
// -----------------------------------------------------------------------------

// From $xsmall breakpoint and wider
@mixin small {
    @media only screen and (min-width: $breakpoint-xsmall) {
        @content;
    }
}

// From $medium breakpoint and wider
@mixin medium {
    @media only screen and (min-width: $breakpoint-medium) {
        @content;
    }
}

// From $large breakpoing and wider
@mixin large {
    @media only screen and (min-width: $breakpoint-large) {
        @content;
    }
}

// From $xlarge breakpoint and wider
@mixin xlarge {
    @media only screen and (min-width: $breakpoint-xlarge) {
        @content;
    }
}


// Descending
// NOTE: The order of these are deliberately ordered to override from widest
// to narrowest (how these mixins are best used in 'descending' order). The
// mixins appear to be compiled in the order in which they are first called
// which is determined at the bottom of this file by the debug flags.
// -----------------------------------------------------------------------------

// From 0 until $medium breakpoint
@mixin medium-down {
    @media only screen and (max-width: $breakpoint-large - 1px) {
        @content;
    }
}

// From 0 until $small breakpoint
@mixin narrow {
    @media only screen and (max-width: $breakpoint-small) {
        @content;
    }
}

// From 0 until $xsmall breakpoint
@mixin xsmall {
    @media only screen and (max-width: $breakpoint-xsmall) {
        @content;
    }
}

// Only applies to IE 10 and IE 11
@mixin ie-only {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        @content
    }
}


/* Breakpoint debug flags for development */
// The order that the mixins are called here determine the order in which they
// are ultimately compiled. Therefore: don't mess with the order unless you
// have thought it through.
// -----------------------------------------------------------------------------
body.develop {
    &:before,
    &:after {
        content: "";
        display: inline-block;
        padding:2px 8px;
        font-size: 0.75em;
        color:#fff;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }
    // Ascending
    &:before {
        right: 0;
        @include small { content: "small"; background: #1abc9c; }
        @include medium { content: "medium"; background: #8e44ad; }
        @include large { content: "large"; background: #c0392b; }
        @include xlarge { content: "xlarge"; background: #2c3e50; }
    }

    // Descending
    &:after {
        left: 0;
        @include medium-down { content: "medium-down"; background: tomato; }
        @include narrow { content: "narrow"; background: #3498db; }
    }
    &:before {
        @include xsmall {  content: "xsmall"; background: #d35400; }
    }
    // IE Specific
    &:before {
        @include ie-only { content: "ie-only"; background: beige; }
    }
}
