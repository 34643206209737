@import '../../framework_deprecated/settings/index';

$font-family-primary: "aktiv-grotesk", sans-serif;

$transparency: 0.6;

$color-site-black: #000000;
$color-site-white: #FFFFFF;
$color-site-off-white: #F3F3F3;
$color-light-grey: #E5E5E5;
$color-grey: #D9D9D9;
$color-dark-grey: #555555;
$color-site-red: #D3013E;
$color-site-pink: #FC016A;
$color-site-dark-pink: darken(#FC016A, 15%);

$color-grey-transparent: rgba($color-grey, $transparency);

$color-primary: $color-site-black;
$color-primary-inverse: $color-site-white;

$color-secondary: $color-site-pink;
$color-secondary-hover: $color-site-dark-pink;
$color-secondary-inverse: $color-site-white;
$color-secondary-inverse-hover: $color-site-off-white;

$color-tertiary: $color-site-red;
$color-tertiary-inverse: $color-site-white;

