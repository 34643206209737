.search-form {

    .form-layout {
        display: flex;
    }

    .input--search {
        flex: 1 1 100px;
        border: none;
        margin-right: 10px;
        max-width: 420px;

      @include narrow {
        max-width: 185px;
        font-size: 17px;
      }
    }

    .search-submit {
      width: inherit;
    }



}