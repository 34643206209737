.breadcrumbs {

}

.breadcrumbs__item {
    @extend .h6;
    color: $color-primary;
    padding-left: 2em;
    position: relative;
    white-space: nowrap;

    &:before {
    	// Just using default CSS entity for now, change to nicer icon if time
    	content: "\25b6";
    	position: absolute;
    	top: -0.2em;
    	left: 0.65em;
    }

    &:first-child {
    	padding-left: 0;

    	&:before {
    		display: none;
    	}
    }

    &:hover {
        &:before {
            color: $color-primary;
        }
    }
}
