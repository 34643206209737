
// ---------------------------------------------------------------------------
// Columns
// If you need or prefer a column based you can use the following mixin
// to generate the column, offset & push classes you need.
// ---------------------------------------------------------------------------
@mixin columns($size:'', $num_cols:12) {
    $prefix: if($size == '', '', #{$size}-);

    $columnName: 'c';
    $marginLeftName: 'o';
    $marginRightName: 'p';

    // Generate column widths
    @for $i from 1 through $num_cols {
      .#{$prefix}#{$columnName}#{$i}  { width: $i / $num_cols * 100% }
    }

    // Generate offset & push rules ( margin left). 0 provides reset rule
    @for $i from 0 through $num_cols - 1 {
      .#{$prefix}#{$marginLeftName}#{$i} { margin-left: $i / $num_cols * 100%; }
      .#{$prefix}#{$marginRightName}#{$i} { margin-right: $i / $num_cols * 100%; }
    }
}


// base widths with no media query
@include columns()

@include narrow { // small down
    @include columns('sm');
}

@include medium {
    @include columns('md');
}

@include large {
    @include columns('lg');
}

@include xlarge {
    @include columns('xl');
}
