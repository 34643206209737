// ------------------------------------
//    #MIXINS
// ------------------------------------

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.


// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix;
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix {
    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

@mixin hover {
    .no-touch &:hover {
        @content;
    }
}

// style placeholder text
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}


// enable/disable font smoothing
@mixin font-smoothing($toggle: true) {
    @if ($toggle == true) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin font-smoothing-off {
    @include font-smoothing(false);
}

// add arrow to block element
@mixin arrow($orientation: 'up', $color: #000, $size: 1em) {

    position: relative;

    &:after {
        position: absolute;
        content: '';

        width: 0;
        height: 0;
        z-index: 7;

        @if ($orientation == 'up') {

            border-top: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-bottom-width: $size;
            border-bottom-style: solid;
            border-bottom-color: $color;

        } @else if ($orientation == 'down') {

            border-bottom: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-top-width: $size;
            border-top-style: solid;
            border-top-color: $color;

        } @else if ($orientation == 'right') {

            border-right: 0;
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;

            border-left-width: $size;
            border-left-style: solid;
            border-left-color: $color;

        } @else if ($orientation == 'left') {

            border-left: 0;
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;

            border-right-width: $size;
            border-right-style: solid;
            border-right-color: $color;

        }

    }

}

@mixin abs($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: absolute;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}

@mixin fixed($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: fixed;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}

@mixin relative-font($font-size, $line-height) {
    font-size: rem($font-size);
    line-height: line-height($font-size, $line-height);
}

@mixin hide-text {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

@mixin list-clear {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        padding: 0;
    }
}

// This unusual method is how Medium creates nice underlines in its articles.
// Documented here: https://medium.design/crafting-link-underlines-on-medium-7c03a9274f9
// Must be well-supported? Check well in testing.
@mixin fancy-underline(
    $underline-color: $color-site-white,
    $underline-width: 4px,
    $underline-start: 0px,
    $underline-end: $underline-start + $underline-width
    ) {
    background-image: linear-gradient(to top, transparent, transparent $underline-start, $underline-color $underline-start, $underline-color $underline-end, transparent $underline-end);
}
