.collapse {
    display: none;

    &.collapsible-show {
        display: block;
        animation: fadeIn 0.2s ease-in;
    }
}

.collapse-control--open {
    .triangle-down svg {
        transform: rotate(180deg);
    }
}


