.list-inline {
    margin-left: 0;
    padding: 0;
    list-style: none;

    li, dd {
    	padding: 0;
    	margin: 0;
    }
}

    .list-inline__item {
        display: inline-block;

        & + & {
            margin-left: $spacing-small;
        }
    }
