.header {
    background-color: $color-primary;
    background-image: url("/static/images/hero-grey-min.png");
    padding: $content-padding;

    @include medium {
        padding: 1em $col-spacing--medium;
    }

    @include large {
        padding: 1em $col-spacing--large / 2;
    };
}

.header__logo {

    display: block;
    width: rem(112px);
    height: rem(66px);

    // ÷@include hide-text;

}

.desktop-logo {
    width: rem(112px);
    height: rem(66px);
}

.mobile-logo {
    width: 113px;
    height: 37px;
}


.main-nav {
    @include list-clear;
    display: none; // Shown on larger screens using .md-flex
}

.main-nav__item {
    display: inline-block;
}

.main-nav__item-link {
    color: $color-primary-inverse;
    display: inline-block;
    font-weight: $font-weight-bold;
    padding: 1rem;
    transition: $base-transition;
    font-size: rem(16px);

    span {
        width: 100%;
        border-bottom: solid rem(4px) transparent;
    }

    &:hover {
        color: $color-primary-inverse;

        span {
            border-bottom-color: $color-secondary;
            transition: none;
        }
    }

    .icon {
        vertical-align: middle;
    }
}

.main-nav__item_dropdown,
.main-nav__item_dropdown--inverted {

    @include arrow(
        $orientation: 'down',
        $color: $color-primary-inverse,
        $size: 0.25rem
    );


    &:after {
        right: 0.2rem;
        top: 1.8rem;
        transition: $base-transition;
    }

    &:hover {
        &:after {
            border-top-color: $color-secondary;
            transition: none;
        }
    }
}

.main-nav__item_dropdown--inverted {
    @include arrow(
        $orientation: 'up',
        $color: $color-primary-inverse,
        $size: 0.25rem
    );
}

.shortlist-count {
    $counter-dimension: 18px;
    $counter-line-height: 20px;

    @include utils(absolute, rounded-circle, font-medium, color-white, bg-red, inline-block, text-center);
    font-size: rem(13px);
    width: rem($counter-dimension) !important;
    height: rem($counter-dimension);
    line-height: rem($counter-line-height);
    top: rem(8px);
    right: rem(2px);

    &.--update {
        animation: pulse 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2s;
    }

    @include breakpoint-up('md') {
        top: rem(3px);
        right: rem(-1px);
    }

    @include breakpoint-up('md+') {
        top: rem(9px);
        right: rem(4px);
    }
}



