
.list-unstyled {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

    .list-unstyled > li,
    .list-unstyled > dd {
        list-style: none;
        padding-left: 0;
    }


/**
### Block list
Throws a list into a vertical stack. Handy for things like navigation.
*/
.block-list {
    @extend .list-unstyled;
    > li,
    > dd {
        display: block;
    }
}