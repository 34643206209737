.richtext-image {

    &.left {
        float: left;
        margin-right: 2rem;
    }

    &.right {
        float: right;
        margin-left: 2rem;
    }
}

.document-download {
    display: flex;
    align-items: center;
}

.content-style-standard {
    h2 {
        padding-bottom: 0.2rem;
        border-bottom: solid .5625rem $color-primary;
    }
}
