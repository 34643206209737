.notification {
    border-radius: rem(1px);
    padding: rem(18px) rem(55px);
    position: relative;

    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            opacity: $transparency;
        }
    }

    strong {
        font-weight: $font-weight-bold;
    }

    p {
        margin: 0;
    }
}

.notification_warning, .notification--general, .notification--positive, .notification--negative {
    background-color: $color-tertiary;
    color: $color-tertiary-inverse;

    background-image: icon(
        exclamation,
        $color: $color-primary-inverse
    );
    background-repeat: no-repeat;
    background-position: 1rem center;
}

.notification__content {
    @include relative-font(
        $font-size: 14px,
        $line-height: 19px
    );
}

.notification--bad {
    background: rgba(#F37B70, 0.3);
}
