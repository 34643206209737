.btn {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    transition: $base-transition;

    .icon {
        vertical-align: middle;
    }

    &:hover {
        transition: none; // Only apply transition on :hover so it feels snappy
    }
}

// Shadow
// .btn_primary, .btn_secondary {
//     margin-bottom: rem(5px);
//     box-shadow: 0px rem(20px) 0 rem(-15px) rgba($color-primary, 0.08);

//     @include medium {
//         box-shadow: 0px rem(30px) 0 rem(-25px) rgba($color-primary, 0.08);
//     }
// }

.btn_primary, .btn--default, .btn--primary {
    background-color: $color-secondary;
    color: $color-secondary-inverse;
    letter-spacing: rem(0.8px);
	line-height: rem(27px);
    padding: rem(16px);
	text-align: center;
    font-size: rem(16);

    @include xsmall {
        font-size: rem(14px);
        padding: rem(14px)
    }

    @include medium {
        font-size: rem(18px);
        padding-left: rem(24px);
        padding-right: rem(24px);
    }

    &:hover {
        background-color: $color-secondary-hover;
        color: $color-secondary-inverse;
    }
}

.btn_secondary {
    background-color: $color-primary-inverse;
    color: $color-primary;
    padding: rem(16px);
    font-size: rem(16);
    line-height: rem(27px);

    @include xsmall {
        font-size: rem(14px);
        padding: rem(14px)
    }

    &:hover {
        background-color: $color-secondary-inverse-hover;
        color: $color-primary;
    }
}

.btn_tertiary {
    background-color: $color-secondary-inverse;
    color: $color-secondary;
    margin-left: rem(-10px);
    margin-right: rem(10px);
    padding: rem(5px) rem(10px);

    @include relative-font(
        $font-size: 14px,
        $line-height: 27px
    );

    &:hover {
        background-color: $color-secondary-inverse-hover;
        color: $color-secondary-hover;
    }
}

.btn_gallery {
    background-color: rgba($color-primary, $transparency);
    color: $color-primary-inverse;
    padding: rem(5px) rem(10px);

    @include relative-font(
        $font-size: 14px,
        $line-height: 27px
    );
}

.btn_inactive {
    opacity: 0.5;
}

.btn + .btn,
.btn + [data-large-btn] {
    margin-left: rem(8px);

    @include narrow {
        margin-left: 0;
    }

    @include medium {
        margin-left: rem(16px);
    }
}
