// UTILITIES
// Align................Various alignment patterns.
// Headings.............Assigning our heading styles to helper classes.
// Margins..............Some custom margins.
// Text.................Typographic utilities.
// Visibility...........Show/hide stuff.
// Widths...............Simple width helper classes.
// / ...................All the other helper stuff.

@import 'align';
@import 'margins';
@import 'text-utilities';
@import 'visibility';
@import 'widths';
@import 'padding';
@import 'sentiment';


// --------------------------------------------------
// Generic utility classes
// -------------------------------------------------


// Prevent all user interaction with the element
.u-disabled {
    pointer-events: none !important;
    cursor: default !important;
}

// An accessible label that takes up no visual space
.u-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); // Deprecated but still needed to support Firefox & IE
    border: 0;
    clip-path: inset(1px); // New (experimental) CSS property
}

.u-transition {
    transition: $base-transition !important;
}

.u-list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

.u-pos-relative {
    position: relative;
}

.u-cursor--pointer {
    cursor: pointer;
}
