// --------------------------------------------------
// Images
// --------------------------------------------------

// 1. Fluid images for responsive purposes.
// 2. Offset `alt` text from surrounding copy.
// 3. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.
img {
    height: auto;
    max-width: 100%; // [1]
    font-style: italic; // [2]
    vertical-align: middle; // [3]
}


.bw-image,
.bw-image img {
    width: 100%;
    transition: none;
    //filter: grayscale(100%) contrast(110%);
}

.oversized-image {
	position: relative;
	display: inline-block;

	&, img { // Make all images will parent container
		width: 100%;
	}

	cite {
		font-style: normal;
		display: inline-block;
		padding: 0.1em 0.5em 0.2em;
		font-size: rem(12);
		background: rgba(0,0,0,0.5);
		color: rgba(255, 255, 255, 0.5);
		position: absolute;
		top: 0;
		right: 0;

		&:before {
			content: "Image: ";
		}
	}
}
