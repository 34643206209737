
// ----------------------------------------------------------------------------
// BASE FLEX SETUP
// ----------------------------------------------------------------------------


.flex {
  display: flex;

}

// Use for medium and above
.md-flex {

    @include medium {
        display: flex;
    }

}

// Ugh. This isn't pretty.
.md-flex--xl-grid {

    @include medium {
        display: flex;
    }

    @include xlarge {
        display: inline-block;
    }
}

// Use for large and above
.lg-flex {

    @include large {
        display: flex;
    }

}

// Flex for medium and below
.md-down-flex {
    display: flex;

    @include large {
        display: block;
    }

}


// Break a flexbox layout and stack for mobile
.narrow-stack {

    @include narrow {
        display: block;
        background: none !important;
    }

}



// ----------------------------------------------------------------------------
// ALIGN CHILDREN FROM PARENT
// ----------------------------------------------------------------------------

.flex--align-top {
    align-items: flex-start;
}

.flex--center {
    align-items: center;
}

.flex--bottom {
    align-items: flex-end;
}

.flex--right {
    justify-content: flex-end;
}

.flex--justify-center {
    justify-content: center;
}



// ----------------------------------------------------------------------------
// ALIGN CHILDREN
// ----------------------------------------------------------------------------

.layout-flex-item--top {
    align-self: flex-start;
}

.flex-item--bottom {
    align-self: flex-end;
}

.flex-item--right {
    margin-left: auto;
}

.flex-item--first {
    order: -1;
}

.flex-item--full-width {
    flex: 1;
}


// ----------------------------------------------------------------------------
// LAYOUT SPACING
// ----------------------------------------------------------------------------

.flex--justify-center {
    justify-content: center;
}

.flex--space-between {
    justify-content: space-between;
}

.flex--padding  > div {
    padding-left: 1em;
    padding-right: 1em;

    @include narrow {
        padding-top: 1em;
    }

}




// ----------------------------------------------------------------------------
// HELPER CLASSES
// ----------------------------------------------------------------------------

.flex--row-reverse {
    flex-direction: row-reverse;
}

.md-flex--row-reverse {
    @include medium {
        flex-direction: row-reverse;
    }
}

.flex--column {
    flex-direction: column;
}

.layout-flex img {
    object-fit: scale-down;
}

.flex--column-last-bottom {
    height: 100%;

    .align-bottom {
        margin-top: auto;
    }
}

.flex--wrap {
    flex-flow: wrap;
}


// Swap ordering, make this element appear first
.flex--order-first {
    order: -1;
}

.flex--order-last {
    order: 1;
}




// ----------------------------------------------------------------------------
// Preset layouts
// ----------------------------------------------------------------------------


.flex-layout {
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
}

.flex-layout--halfs {

    > * {
        margin-bottom: 50px;

        @include medium {
            flex: 1;
            max-width: 48%;
            flex: 0 0 48%;
        }

    }

}


.flex-layout--thirds {

    @include medium {
        justify-content: space-between;
    }

    @include large {
        justify-content: flex-start;
    }

    > * {

        @include narrow {
            margin-bottom: 50px;
        }

        @include medium {
            max-width: 48%;
            flex: 0 0 48%;
            margin-bottom: 50px;
        }

        @include large {
            flex: 1;
            max-width: 31%;
            flex: 0 0 31%;
            margin-right: 3.5%;

            &:nth-child(3n) {
                margin-right: 0;
            }

        }

    }

}


.flex-layout--quarters {

    > * {
        margin-bottom: 50px;

        @include medium {
            max-width: 48%;
            flex: 0 0 48%;
        }

        @include large {
            max-width: 23%;
            flex: 0 0 23%;
        }

    }

}


.flex-layout--listing {

    > * {

        @include medium {
            flex: 0 0 50%;
            // padding-left: $col-spacing--medium / 2;
            // padding-right: $col-spacing--medium / 2;
        }

        @include large {
            flex: 1 1 calc(1 / 3 * 100%);
            max-width: calc(1 / 3 * 100%);
            // padding-left: $col-spacing--large / 2;
            // padding-right: $col-spacing--large / 2;
        }

    }
}

.flex-layout--event-card-large {

    @include large {
        .card__image {
            width: calc(2 / 3 * 100%);
        }

        .card__body {
            width: calc(1 / 3 * 100%);
        }
    }

}


.flex-sponsors {
    justify-content: flex-start;

    > * {

        display: flex;
        align-items: center;

        @include narrow {
            max-width: 48%;
            flex: 0 0 48%;
            margin-bottom: 50px;
        }

        @include medium {
            max-width: 48%;
            flex: 0 0 48%;
            margin-bottom: 50px;
        }

        @include large {
            flex: 1;
            max-width: 26%;
            flex: 0 0 26%;
            margin-right: 10%;

            &:nth-child(3n) {
                margin-right: 0;
            }

        }

    }

}
