@keyframes loading {
    0% { opacity: 1; }
    50% { opacity: .5; }
    100% { opacity: 1; }
}

@keyframes fade-in--60 {
    0% { opacity: .6; }
    100% { opacity: 1; }
}

@keyframes fade-out--60 {
    0% { opacity: 1; }
    100% { opacity: .6; }
}

@keyframes slideOut {
    0% { transform: translateY(0) }
    100% { transform: translateX(-100%) }
}

@keyframes slideIn {
    0% { transform: translateX(-100%) }
    100% { transform: translateY(0) }
}

@keyframes slideUp {
    0% { transform: translateY(0) }
    100% { transform: translateY(-100%) }
}

@keyframes slideDown {
    0% { transform: translateY(-100%) }
    100% { transform: translateY(0) }
}

