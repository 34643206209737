.venues__major-venue {
    background-image: icon(
        map-pin,
        $color: $color-secondary
    );
    background-repeat: no-repeat;
    background-position-y: 0.5rem;
    padding-left: rem(35px);

    p {
        margin: 0;
    }
}
