
.form_from_cms {
	max-width: 25em;
}

label {
    display: block;
    @extend .u-large;
    margin-bottom: rem(8px);
}

.helptext {
	@extend .u-xsmall;
	@extend .u-muted;
	display: block;
	margin-top: rem(8px);
}

textarea,
input[type=time],
input[type=date],
input[type=number],
input[type=password],
input[type=email],
input[type=url],
input[type=text],
select {
	width: 25em;
	max-width: 100%;
	background: $color-primary-inverse;
	padding: 0.75em;
	border: 3px solid $color-primary;
	font-size: rem(18);
	font-weight: $font-weight-bold;

	&::placeholder {
		color: rgba(#000, .3);
	}

	@include narrow {
		width: 100%;
	}

}

.input--large {
	min-height: rem(56);
}

select {
	background: transparent;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
	border-radius: 0;
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Event' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Event-1-(L)' transform='translate(-1116 -1162)' fill-rule='nonzero' stroke='%23FE00B2' stroke-width='2.686'%3E%3Cpath d='M1120.5 1161.5l6.856 5.681a.427.427 0 0 1 0 .638l-6.856 5.681' id='icon--chevron-down' transform='rotate(90 1124 1167.5)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) 50%;
}

select[multiple] {
	background: none;
}

select::-ms-expand {
	display: none;
}


ul.checkbox,
ul.radio {
	list-style: none;
	padding-left: 0;
	margin-left: 0;

	li {
		padding-left: 0;
	}

}

.fieldset--error {
    .select-style,
    input[type='text'],
	input[type='password'],
    input[type='email'] {
        border-color: $color-tertiary !important;
        border-width: 2px !important;
    }

}













// -----------------------------------------------------------------------------
// Better checkboxs
// -----------------------------------------------------------------------------


[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 25px;
    height: 25px;
    border: 3px solid #000;
    background: #fff;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
	content: '';
	display: block;
	position: absolute;
	top: 3px;
	left: 10px;
	width: 6px;
	height: 14px;
	border: solid #7c01ff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    // transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    // transform: scale(1);
}


.checkbox-list {
	list-style: none;
	padding-left: 0;
	margin-left: 0;

	li {
		padding-left: 0;
	}
}


















