html {
    -webkit-tap-highlight-color: transparent;
    color: $color-site-black;
    font-family: $font-family-primary-sans-serif;
    font-size: $base-font-size;
    height: 100%;
    line-height: 1.45;
    min-height: 100%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
}
