.tag {
    border-radius: rem(1px);
    display: inline-block;
    font-weight: $font-weight-bold;
    letter-spacing: rem(0.7px);
    line-height: rem(16px);
    padding: rem(4px) rem(8px);
    text-transform: uppercase;
    white-space: nowrap;

    @include relative-font(
        $font-size: 13px,
        $line-height: 16px
    );
}

.tag_primary {
    background-color: $color-primary;
    color: $color-primary-inverse;
}

.tag_secondary {
    background-color: $color-light-grey;
    color: $color-dark-grey;
}

.tag_urgent {
    background-color: $color-tertiary;
    color: $color-primary-inverse;
}

.tag_expired {
    background-color: $color-primary-inverse;
    color: $color-tertiary;
}
