// -----------------------------------------------------------------------------
// Inline Block Grid
// -----------------------------------------------------------------------------

.grid {
    @include clearfix;
    margin:  0;
    padding: 0;
    list-style: none;
    font-size: 0;
}

.grid > * {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: medium;
    text-justify: auto;
}


// -----------------------------------------------------------------------------
// Universal Grid spacing
// -----------------------------------------------------------------------------

.grid--row-spacing {
    margin-bottom: $col-spacing;

    > * {
        margin-bottom: $col-spacing;
    }
}

.grid--col-spacing {
    @include medium {
    
        margin-left: -$col-spacing--medium;

        > * {
            padding-left: $col-spacing--medium;
        }
    }


    @include large {
    
        margin-left: -$col-spacing--large;

        > * {
            padding-left: $col-spacing--large;
        }
    }

    & > .grid--remove-gutter {
        padding-left: 0;
    }
}


.grid-col--bleed-md-down {
    width: auto;

    @include medium-down {
        // margin-left: -$content-padding--medium; // Not needed
        margin-right: -$content-padding--medium;
    }

    @include narrow {
        margin-left: -$content-padding; // Needed because .grid--col-spacing no longer applies negative margin at this bp
        margin-right: -$content-padding;
    }

}


// // Out of action currently. Adds half padding to both sides, instead of just the left
// .grid--col-spacing-x {
//     @include medium {
    
//         margin-left: -$col-spacing--medium / 2;
//         margin-right: -$col-spacing--medium / 2;

//         > * {
//             padding-left: $col-spacing--medium / 2;
//             padding-right: $col-spacing--medium / 2;
//         }
//     }


//     @include large {
    
//         margin-left: -$col-spacing--large / 2;
//         margin-right: -$col-spacing--large / 2;

//         > * {
//             padding-left: $col-spacing--large / 2;
//             padding-right: $col-spacing--large / 2;
//         }
//     }


//     & > .grid--remove-gutter {
//         padding-left: 0;
//         padding-right: 0;
//     }
// }


// -----------------------------------------------------------------------------
// Grid item alignment modifiers - they are top aligned by default
// -----------------------------------------------------------------------------

.grid--middle > * {
    vertical-align: middle;
}

.grid--bottom > * {
    vertical-align: bottom;
}


.grid--reverse {
  direction: rtl;
  & > * {
    direction: ltr;
  }
}

// -----------------------------------------------------------------------------
// Justify grid - push items apart from each other
// -----------------------------------------------------------------------------

.grid--justified,
.grid--polar {
    text-align: justify;
    text-justify: distribute-all-lines;

    > * {
        text-align: left;
    }

    &:after {
        display: inline-block;
        width: 100%;
        content: '';
    }
}



// Dev Helper to show column placement. add debug class to grid.
body.develop .grid--debug {
    outline:2px dashed #2c3e50;
    & > * {
        outline:2px dashed #c0392b;
    }
}


// grid overlay
body.develop .grid-overlay {
    // background-color: rgba(200,100, 0, 0.1);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; bottom: 0; left: 0; right: 0;
    .grid {
        outline:none!important;
        & > * {
            outline:none!important;
            span {
                height: 100%;
                display: block;
                background-color: rgba(0,0,0,0.1);
                outline: 1px dashed orange;
            }
        }
    }
}
