a {
    color: $color-secondary;
    text-decoration: none;
    transition: none; // only transition out, not in

    &:hover {
        color: $color-secondary-hover;
        transition: $base-transition; // only transition out, not in
    }
}

.block-link {
    display: block;
    color: $color-primary;

    &:hover {
        color: $color-primary;

        .block-link__hover {
            color: $color-secondary-hover;
        }
    }
}

.link-inverse {
    &, &:hover {
        color: $color-secondary-inverse;
    }

    &:hover {
        text-decoration: underline;
    }

    &.active {
        text-decoration: underline;
    }
}

.link-alt {
    &, &:hover {
        color: $color-primary;
    }

    &:hover {
        text-decoration: underline;
    }
}

.link-large {
    display: inline-block;
    position: relative;
    @extend h3;

    &.--xlarge {
        @extend .h2;
    }

    &:before {
        border-bottom: rem(4px) solid $color-secondary;
        bottom: rem(-4px);
        content: "";
        position: absolute;
        // transition: $base-transition;
        width: 100%;
    }

    &:hover {
        &:before {
            border-bottom-color: $color-secondary-hover;
        }
    }


    // For .link-inverse (white) links
    &.link-inverse {
        &:before {
            border-bottom-color: $color-primary-inverse;
        }

        &:hover {
            text-decoration: none; // overwrite .link-inverse underline

            &:before {
                border-bottom-color: $color-primary;
            }
        }
    }
}

$hover-arrow-movement: 1px;

.link-large--arrow {
    padding-right: 0.9rem;

    @include arrow(
        $orientation: 'right',
        $color: $color-secondary,
        $size: 0.4rem
    );

    &:after {
        right: 0rem;
        top: 0.3rem;
        // transition: $base-transition;
    }

    &:hover {
        &:after {
            right: rem($hover-arrow-movement);
            border-left-color: $color-secondary-hover;
        }
    }

    // For .link-inverse (white) links
    &.link-inverse {
        &:after {
            border-left-color: $color-primary-inverse;
        }

        &:hover {
            text-decoration: none; // overwrite .link-inverse underline

            &:after {
                right: rem($hover-arrow-movement);
                border-left-color: $color-primary;
            }
        }
    }
}

.link-fancy-underline {
    @include fancy-underline;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        @include fancy-underline(transparent);
    }
}
