hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-grey;
    margin: 1em 0;
    padding: 0;
    clear: both;

    @include large {
        margin: 1.5em 0;
    }
}

.rule--large {
    border-top: rem(6px) solid $color-primary;
    margin: $spacing-large 0;

    @include large {
        margin: $spacing-xlarge 0;
    }
}

    .--light {
        border-top: rem(6px) solid $color-primary;
        opacity: 0.1;
    }
