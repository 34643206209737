.review {
	color: $color-secondary;
}

.review__body {
	p {
		@extend .u-xlarge;
		margin-bottom: 0.25em;
	}
}

.review__body--stars {
	font-size: rem(40);
	letter-spacing: rem(2);
}

cite {
	font-style: normal;
}

.block-review + .block-review {
	margin-top: rem(48px);
}